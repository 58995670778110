<template>
  <div>
    <Disclosure
      as="div"
      class="relative bg-indigo-600 pb-32 overflow-hidden"
      v-slot="{ open }"
    >
      <div
        :class="[
          open ? 'bottom-0' : 'inset-y-0',
          'absolute flex justify-center inset-x-0 left-1/2 transform -translate-x-1/2 w-full overflow-hidden lg:inset-y-0',
        ]"
        aria-hidden="true"
      >
        <div class="flex-grow bg-indigo-600 bg-opacity-75" />
        <svg
          class="flex-shrink-0"
          width="1750"
          height="308"
          viewBox="0 0 1750 308"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity=".1"
            d="M1465.84 308L16.816 0H1750v308h-284.16z"
            fill="#fff"
          />
          <path
            opacity=".05"
            d="M1733.19 0L284.161 308H0V0h1733.19z"
            fill="#fff"
          />
        </svg>
        <div class="flex-grow bg-indigo-600 bg-opacity-75" />
      </div>
      <header class="relative py-10">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold text-white">Settings</h1>
        </div>
      </header>
    </Disclosure>

    <main class="relative -mt-32">
      <div class="max-w-screen-xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">
        <div class="bg-white rounded-lg shadow overflow-hidden">
          <div
            class="
              divide-y divide-gray-200
              lg:grid lg:grid-cols-12
              lg:divide-y-0 lg:divide-x
            "
          >
            <aside class="py-6 lg:col-span-3">
              <nav class="space-y-1">
                <a
                  v-for="item in subNavigation"
                  :key="item.name"
                  :href="item.href"
                  :class="[
                    item.current
                      ? 'bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                      : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                    'group border-l-4 px-3 py-2 flex items-center text-sm font-medium',
                  ]"
                  :aria-current="item.current ? 'page' : undefined"
                >
                  <component
                    :is="item.icon"
                    :class="[
                      item.current
                        ? 'text-teal-500 group-hover:text-teal-500'
                        : 'text-gray-400 group-hover:text-gray-500',
                      'flex-shrink-0 -ml-1 mr-3 h-6 w-6',
                    ]"
                    aria-hidden="true"
                  />
                  <span class="truncate">
                    {{ item.name }}
                  </span>
                </a>
              </nav>
            </aside>

            <div
              class="divide-y divide-gray-200 lg:col-span-9"
              action="#"
              method="POST"
            >
              <div class="py-6 px-4 sm:p-6 lg:pb-8">
                <div>
                  <h2 class="text-lg leading-6 font-medium text-gray-900">
                    Password
                  </h2>
                  <p class="mt-1 text-sm text-gray-500">
                    Update your account password.
                  </p>
                </div>
                <!-- Gallery -->
                <section class="mt-8 pb-16" aria-labelledby="gallery-heading">
                  <!-- Success message -->
                  <div v-if="showSuccess" class="rounded-md bg-green-50 p-4 mb-8">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <!-- Heroicon name: solid/check-circle -->
                        <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>
                      </div>
                      <div class="ml-3">
                        <h3 class="text-sm font-medium text-green-800">
                          Successfully changed password
                        </h3>
                      </div>
                    </div>
                  </div>
                  <!-- Failure message -->
                  <div v-if="showFailure" class="rounded-md bg-red-50 p-4 mb-8">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <!-- Heroicon name: solid/x-circle -->
                        <svg
                          class="h-5 w-5 text-red-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <div class="ml-3">
                        <h3 class="text-sm font-medium text-red-800">Failed to change password</h3>
                        <div class="mt-2 text-red-700">
                          <p class=" text-red-700 text-sm ">Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum similique veniam.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col">
                    <div class="md:w-1/2">
                      <div>
                        <label for="password" class="block text-sm font-medium text-gray-700">
                          New Password
                        </label>
                        <div class="mt-1">
                          <input @input="changePasswordField('password1', $event.target.value)" id="password" name="password" type="password" autocomplete="current-password" required="" class="appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                        </div>
                      </div>
                      
                      <div class="mt-4">
                        <label for="password" class="block text-sm font-medium text-gray-700">
                          Confirm Password
                        </label>
                        <div class="mt-1">
                          <input @input="changePasswordField('password2', $event.target.value)" id="password2" name="password" type="password" autocomplete="current-password" required="" class="appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                        </div>
                      </div>
                      </div>
                   </div>
                </section>
                
                      
              </div>
              
                <div class="mt-4 py-4 px-4 flex justify-end sm:px-6">
                  <button
                    type="submit"
                    class="
                      ml-5
                      border border-transparent
                      rounded-md
                      shadow-sm
                      py-2
                      px-4
                      inline-flex
                      justify-center
                      text-sm
                      font-medium
                      text-white
                      focus:outline-none
                      focus:ring-2 focus:ring-offset-2 focus:ring-blue-400
                    "
                    :disabled="!doMatch"
                    v-on:click="savePassword"
                    v-bind:class="[
                    doMatch
                      ? 'bg-indigo-600 hover:bg-blue-700'
                      : 'bg-indigo-400 cursor-not-allowed',
                  ]"
                  >
                    Save
                  </button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  <div v-if="!isPremium" class="fixed bottom-0 inset-x-0 pb-2 sm:pb-5  hidden">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="p-2 rounded-lg bg-yellow-400 shadow-lg sm:p-3">
        <div class="flex items-center justify-between flex-wrap">
          <div class="w-0 flex-1 flex items-center">
            <span class="flex p-2 rounded-lg bg-yellow-600">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" viewBox="0 0 20 20" fill="currentColor">
                <path vector-effect="non-scaling-stroke" fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
              </svg>
            </span>
            <p class="ml-3 font-medium text-white truncate">
              <span class="md:hidden text-black">
                Unlock additional features with a paid membership!
              </span>
              <span class="hidden md:inline text-black">
                 Unlock additional features with a paid membership!
              </span>
            </p>
          </div>
          <div class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
            <a href="/Billing" class="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-black-600 bg-white hover:bg-yellow-50">
              See Pricing Options
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import { ref } from "vue";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Switch,
  SwitchDescription,
  SwitchGroup,
  SwitchLabel,
} from "@headlessui/vue";
import { SearchIcon } from "@heroicons/vue/solid";
import {
  BellIcon,
  //CogIcon,
  CreditCardIcon,
  KeyIcon,
  MenuIcon,
  UserCircleIcon,
  PhotographIcon,
  VideoCameraIcon,
  XIcon,
} from "@heroicons/vue/outline";
import { CookieService } from '../services/cookieService';
import { NopService } from '../services/nopService';
import { OcContentService } from '../services/ocContentService';

const user = {
  name: "John Cooper",
  handle: "johncooper",
  email: "johncooper@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
};
const navigation = [
  { name: "Dashboard", href: "#", current: true },
  { name: "Jobs", href: "#", current: false },
  { name: "Applicants", href: "#", current: false },
  { name: "Company", href: "#", current: false },
];
const subNavigation = [
  { name: "Profile", href: "/Dashboard", icon: UserCircleIcon, current: false },
  { name: "Media", href: "/Media", icon: PhotographIcon, current: false },
  { name: "Videos", href: "/Videos", icon: VideoCameraIcon, current: false },
  { name: "Password", href: "/Password", icon: KeyIcon, current: true },
  // { name: "Membership", href: "/Billing", icon: CreditCardIcon, current: false },
];
const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];
const tabs = [{ name: "Recently Added", href: "#", current: true }];
const videos = [
  {
    type: "",
    name: "",
    id: "",
    title:
      "",
  },
  {
    type: "",
    name: "",
    id: "",
    title:
      "",
  },
  {
    type: "",
    name: "",
    id: "",
    title:
      "",
  },
  {
    type: "",
    name: "",
    id: "",
    title:
      "",
  },
  {
    type: "",
    name: "",
    id: "",
    title:
      "",
  },
  {
    type: "",
    name: "",
    id: "",
    title:
      "",
  },
  // More people...
];

const passwordValues = {
  password1: "",
  password2: ""
}

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Switch,
    SwitchDescription,
    SwitchGroup,
    SwitchLabel,
    BellIcon,
    MenuIcon,
    SearchIcon,
    XIcon,
  },
  setup() {
    const availableToHire = ref(true);
    const privateAccount = ref(false);
    const allowCommenting = ref(true);
    const allowMentions = ref(true);

    return {
      user,
      navigation,
      subNavigation,
      userNavigation,
      availableToHire,
      privateAccount,
      allowCommenting,
      allowMentions,
      tabs,
      videos,
      passwordValues,
    };
  },
  data() {
    return {
      isLoading: true,
      userData: null,
      sectionData: null,
      doMatch: false,
      isPremium: null,
      showSuccess: false,
      showFailure: false,
    };
  },
  created() {
    this.isLoading = true;
    this.fetchUserData();
    this.fetchOcData();
    this.isLoading = false;
  },
  methods: {
    async fetchUserData() {
      let id = CookieService.getLoginCookie()
      if (id) {
        this.userData = await NopService.GetCustomerDetailsById(id);
        this.isPremium = this.userData.IsPremium;
        this.privateAccount = this.userData.IsAccountPrivate;
      }
      else {
        this.emitter.emit("logged-in", false);
      }
    },
    async fetchOcData() {
      this.sectionData = await OcContentService.fetchPageData(
        "Settings - Profile",
        "KrickersSectionContent"
      );
    },
    getLoginCookie() {
      return CookieService.getLoginCookie()
    },
    changePasswordField(field, value) {
      this.passwordValues[field] = value;
      this.doMatch = (passwordValues.password1 && passwordValues.password2 && passwordValues.password1 === passwordValues.password2)
    },
    async savePassword() {
      this.isLoading = true;
      this.showSuccess = false;
      this.showFailure = false;
      if (this.doMatch) {
        this.userData.Password = this.passwordValues.password1;
        this.userData["IsActive"] = 1;
        let resp = await NopService.UpdateCustomer(this.userData);
        if (resp.Status === 1) {
          this.showSuccess = true;
        }
        else {
          this.showFailure = true;
        }
      }
      this.clearFields();
    },
    clearFields() {
      let password1 = document.getElementById("password");
      let password2 = document.getElementById("password2");
      password1.value = "";
      password2.value = "";
      this.passwordValues.password1 = "";
      this.passwordValues.password2 = "";
      this.doMatch = false;
    }
  },
};
</script>
